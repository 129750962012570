$color-dark-purple: #05032D;
$color-blue: #477BE4;
$color-peach: #E5A37B;
$color-green: #BAAE57;
$color-white: #FFFFFF;
$color-dark: #000000;
$color-light-grey: #CCCCCC;
$color-red: #b0122c;
$background-color: #fdfdfd;

$color-cta-background: #faf8f3;
$link-button-color: #faf8f3;

$text-color: #111111;
$brand-light: $color-peach;
$brand-dark: $color-dark-purple;
$brand-color: $color-blue;
$divider-color: rgba($brand-dark, 0.3);

$nav-color: $color-white;
$header-background-color: $brand-dark;
$hamburger-menu-background-color: rgba(0,0,0,.93);
$dropdown-menu-background-color: rgba(0,0,0,.93);
$footer-color: $color-dark;
$footer-background-color: $brand-light;
$footer-copyright-notice-background-color: $brand-light;
$footer-copyright-notice-color: $color-dark;

$small-spacing: 5px;
$medium-spacing: 10px;
$default-spacing: 15px;
$large-spacing: 30px;
$extra-large-spacing: 60px;

$header-height: 100px;
$footer-height: 100px;

$base-font-family: 'Lato', sans-serif;
$content-width: 1200px;
$on-laptop: 800px;

$width-hamburger: 1000px;