.sr-only {
    @include sr-only();
}

.sr-only-focusable {
    @include sr-only-focusable();
}

.site-header {
    background-color: $brand-dark;
    .page-link {
        text-transform: uppercase;
    }
    .submenu .page-link {
        text-transform: none;
    }
}

.site-title {
    max-width: 70%;
}

.site-title svg {
    fill: $brand-light;
    max-width: 100%;
    height: 60px;
    .logo-divider {
        fill-opacity: 0.49;
    }
    .logo-words {
        fill: $color-white;
    }
}

body.with-masthead {
    .site-header {
        background-color: transparent;
        @include media-query($width-hamburger) {
            &.nav-open {
                background-color: rgba(0,0,0,0.85);
            }
        }
    }


    .site-title svg {
        fill: $brand-light;
        .logo-words {
            fill: $color-white;
        }
    }

    .page-content {
        margin-top: -1 * $header-height;
    }
}

body.without-masthead {
    .site-header {
        background: url(/assets/images/site/wood-header-bg.jpg);
        background-size: cover;
        background-position: top 50%;
        @include media-query($width-hamburger) {
            &.nav-open {
                background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(/assets/images/site/wood-header-bg.jpg);
                background-size: cover;
                background-position: top 50%;
            }
        }
    }
}

.page-content.home-page {
    padding-top: 0;
}

.masthead {
    height: 50vh;
    min-height: 30rem;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
    .head-text {
        font-size: 1.8rem;
        background-color: rgba(0,0,0,0.5);
        color: $color-white;
        font-weight: 300;
        text-align: center;
        padding: $large-spacing*2 $large-spacing $large-spacing;
        //@include media-query($on-palm) {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        //}

        .head-button {
            margin-top: $default-spacing;
            a {
                display: block;
                border: solid 1px $color-white;
                border-radius: 5px;
                color: inherit;
                padding: 5px $default-spacing;
                font-size: 1.3rem;
                &:hover,
                &:focus,
                &:active {
                    background-color: $color-white;
                    color: $text-color;
                    text-decoration: none;
                }
            }
        }
    }
}

a.link-button {
    display: inline-block;
    border-radius: 5px;
    border: solid 1px $link-button-color;
    background-color: $link-button-color;
    color: $color-dark;
    padding: 5px $default-spacing;
    font-size: 1.1rem;
    &:hover,
    &:focus,
    &:active {
        background-color: $color-light-grey;
        color: $link-button-color;
        text-decoration: none;
    }
    &.inverted {
        border: solid 1px $color-white;
        background-color: $color-white;
        color: $link-button-color;
        &:hover,
        &:focus,
        &:active {
            background-color: $link-button-color;
            color: $color-dark;
            border: solid 1px $color-white;
        }
    }
    &.colorful {
        border: solid 2px #FFFFFF;
        background-color: #f2d0bb;
        color: $color-dark;
        &:hover,
        &:focus,
        &:active {
            border: solid 2px #05032D;
        }
    }
}

ul.features {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    grid-gap: 60px;
    justify-content: space-evenly;
    margin-bottom: 45px;
    color: $brand-dark;
    li {
        flex: 1;
        max-width: 300px;
        text-align: center;
        font-size: 1.1rem;
        a {
            img {
                width: 70%;
                max-width: 80px;
            }
            span {
                display: block;
                text-decoration: none;
                color: $brand-dark;
            }
            &:hover {
                text-decoration: underline;
                span {
                    text-decoration: none;
                }
            }
        }
    }
    @include media-query($on-laptop) {
        flex-wrap: wrap;
        margin-right: 50px;
        margin-left: 50px;
        grid-gap: 60px 0;
        li {
            flex: 0 0 50%;
        }
    }
    @include media-query($on-palm) {
        flex-direction: column;
        align-items: center;
        grid-gap: 30px;
        li {
            margin-bottom: 0;
        }
    }
}

.content-divider {
    width: 50%;
    margin: 50px auto;
    color: $divider-color;
}
hr {
    color: $divider-color;
    margin: 30px 0;
}

.blurb {
    font-size: 1.1em;
    margin: 0 auto;
    &.color-bg {
        background-color: $color-cta-background;
        color: $color-white;
    }
}
.color-bg {
    background-color: $color-cta-background;
    color: $color-dark;
    .blurb {
        padding-top: 45px;
        padding-bottom: 45px;
    }
    a {
        color: $color-dark;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    a.link-button {
        border: solid 1px $color-dark;
        background-color: $color-cta-background;
        color: $color-dark;
        text-decoration: none;
        margin-top: $large-spacing;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-light-grey;
            color: $color-cta-background;
        }
    }
}

.blurb-link {
    text-align: center;
}

.callout {
    margin: 45px 0;
    padding: 45px 0;
    background-color: $brand-color;
    font-size: 1.1em;
    color: $color-white;
    .callout-content {
        display: flex;
        flex-direction: row;
        .callout-image-left {
            flex-basis: 300px;
        }
        .callout-text-right {
            flex: 1;
            padding-left: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .callout-link {
                margin: 0;
                text-align: right;
            }
        }
        @include media-query($on-laptop) {
            flex-direction: column;
            .callout-image-left {
                text-align: center;
                flex-basis: auto;
                margin-bottom: 30px;
                img {
                    width: 300px;
                    max-width: 100%;
                }
            }
            .callout-text-right {
                flex: 1;
                padding-left: 0;
                .callout-link {
                    text-align: center;
                }
            }
        }
    }
    a {
        color: $color-white;
        text-decoration: underline;
        &.link-button {
            text-decoration: none;
            display: inline-block;
            border-radius: 5px;
            padding: 5px $default-spacing;
            border: solid 1px $color-white;
            background-color: $color-white;
            color: $link-button-color;
            &:hover,
            &:focus,
            &:active {
                background-color: $link-button-color;
                color: $color-white;
                border: solid 1px $color-white;
            }
        }
    }

    &.flush-image {
        padding-bottom: 0;
        padding-top: 0;
        .callout-content {
            .callout-image-left {
                flex-basis: 400px;
                padding-top: 15px;
                img.show-narrow {
                    display: none;
                }
                img.show-wide {
                    display: block;
                }
            }
            .callout-text-right {
                padding: 30px 0px 30px;
                &.space-left {
                    padding-left: 30px;
                }
            }
        }
        @include media-query($on-laptop) {
            padding: 45px 0;
            .callout-content {
                flex-direction: column;
                .callout-image-left {
                    text-align: center;
                    flex-basis: auto;
                    margin-bottom: 0;
                    padding-top: 0;
                    img {
                        width: 300px;
                        max-width: 100%;
                    }
                    img.show-narrow {
                        display: inline;
                    }
                    img.show-wide {
                        display: none;
                    }
                }
                .callout-text-right {
                    padding-left: 0;
                    padding-bottom: 0;
                    &.space-left {
                        padding-left: 0;
                    }
                }
            }
        }

        &.white-bg {
            background-color: $color-white;
            margin: 0;
            .callout-content {
                .callout-image-left {
                }
                .callout-text-right {
                    color: $text-color;
                    a.link-button {
                        border: solid 1px $link-button-color;
                        background-color: $link-button-color;
                        color: $color-dark;
                        &:hover,
                        &:focus,
                        &:active {
                            background-color: $color-light-grey;
                            color: $link-button-color;
                        }
                    }
                }
            }
            @include media-query($on-laptop) {
                padding: 45px 0;
            }
            &.divide-bottom {
                border-bottom: solid 1px $divider-color;
                margin-bottom: 45px;
            }
        }
    }
}

.callout-link {
    margin: 60px 30px 30px;
    text-align: center;
}

.recent-news {
    .news-items {
        display: flex;
        justify-content: space-between;
        grid-gap: 30px;
        .news-item {
            h3 {
                a {
                    color: inherit;
                }
            }
            .post-meta {
                display: block;
            }
            background-color: $color-white;
            border: solid 1px $divider-color;
            border-radius: 5px;
            padding: 30px 30px 46px;
            flex: 1;
            position: relative;
            .read-more {
                position: absolute;
                bottom: 30px;
                right: 30px;
                margin-bottom: 0;
            }
        }
        @include media-query(1000px) {
            flex-direction: column;
        }
    }
}

.image-words {
    margin-top: $large-spacing;
    margin-bottom: $extra-large-spacing;
    display: flex;
    .image {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 300px;
        text-align: center;
        img {
            margin: 0 auto;
            max-width: 300px;
            width: 100%;
        }
        &.large {
            flex-basis: 400px;
            img {
                max-width: 400px;
            }
        }
        margin-bottom: $default-spacing;
    }
    .text {
        h2,
        h3 {
            font-size: 1.8rem;
        }
        flex: 1;
    }
    &.image-right {
        .image {
            order: 2;
            padding-left: $default-spacing;
        }
        .text {
            order: 1;
            padding-right: $default-spacing;
        }
    }
    &.image-left {
        .image {
            order: 1;
            padding-right: $default-spacing;
        }
        .text {
            order: 2;
            padding-left: $default-spacing;
        }
    }
    @include media-query($on-laptop) {
        .image,
        .image.large {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }
        &.image-left,
        &.image-right {
            .text,
            .image {
                padding-right: 0;
                padding-left: 0;
            }
            .image {
                order: 1;
            }
            .text {
                order: 2;
            }
        }
        flex-direction: column;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}
.standout-heading {
    font-size: 2rem;
    margin-top: $large-spacing;
    margin-bottom: $large-spacing;
    text-align: center;
}

.align-center {
    text-align: center;
}
.uppercase {
    text-transform: uppercase;
}
.section-start-spaced {
    margin-top: $extra-large-spacing;
}

.image-float {
    max-width: 400px;
    width: 50%;
    &.float-left {
        float: left;
        margin-right: $large-spacing;
    }
    &.float-right {
        float: right;
        margin-left: $large-spacing;
    }
    margin-bottom: $default-spacing;
    @include media-query($on-palm) {
        &.float-left,
        &.float-right {
            margin-right: auto;
            margin-left: 0;
            float: none;
        }
        display: block;
        width: 100%;
        max-width: 300px;
        &.hide-mobile {
            display: none;
        }
    }
}
.clear {
    clear: both;
}

.image-jump-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .jump-link {
        flex: 0 0 50%;
        padding: 30px;
        box-sizing: border-box;
        //width: 50%;
        img {
            width: 100%;
        }
        .jump-link-text {
            text-align: center;
            color: $text-color;
            font-size: 1.3rem;
        }
    }
    @include media-query($on-palm) {
        flex-direction: column;
    }
}
.service-item {
    display: flex;
    margin-top: 30px;
    margin-bottom: 60px;
    .service-item-title {
        flex-basis: 300px;
        flex-grow: 0;
        flex-shrink: 0;
        img {
            width: 100%;
        }
    }
    .service-item-description {
        flex: 1;
        padding-left: 30px;
    }
    @include media-query($on-palm) {
        flex-direction: column;
        .service-item-title {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
        }
        .service-item-description {
            flex: 1;
            padding-left: 0;
        }
    }
}

.listings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h3, h4 {
        //text-transform: uppercase;
    }
    .service-listing,
    .discount-listing {
        //flex: 0;
        //flex-basis: 48%;
        padding-bottom: 30px;

        @include media-query($on-palm) {
        //    flex-basis: 100%;
        }
    }
    .price {
        font-weight: 700;
        font-style: italic;
        text-align: center;
    }
}

footer {
    background-color: $footer-background-color;
    color: $footer-color;
}

.site-footer {
    display: flex;
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;
    .footer-column {
        flex: 0;
        flex-basis: 33.3333%;
        padding: $default-spacing;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    address {
        font-style: normal;
        font-size: 0.9em;
        width: 100%;
        a {
            display: block;
            color: $text-color;
        }
    }
    .footer-social {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: baseline;
        a {
            display: block;
            opacity: 0.5;
            line-height: 0;
            &:hover,
            &:focus {
                opacity: 1;
            }
            svg {
                padding: 5px;
                width: 30px;
                height: 30px;
            }
        }
    }
    .footer-seal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include media-query($on-laptop) {
        flex-direction: column;
        padding: $default-spacing $default-spacing 0 $default-spacing;
        .footer-column {
            padding: $small-spacing $default-spacing;
        }
        .footer-contact {
            order: 2;
            text-align: center;
        }
        .footer-social {
            order: 1;
        }
        .footer-seal {
            order: 3;
        }
    }
}
.footer-copyright {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $footer-copyright-notice-background-color;
    color: $footer-copyright-notice-color;
    font-size: 0.8em;
    a {
        color: inherit;
    }
}

.row {
    display: flex;
    justify-content: center;
    .col {
        flex: 1;
    }
    @include media-query($on-laptop) {
        flex-direction: column;
    }
}

.contact-header {
    margin-top: $large-spacing;
    margin-bottom: $large-spacing;
    max-width: 100%;
}
.contact-info {
    padding-right: $default-spacing;
    margin-bottom: $large-spacing;
    @include media-query($on-laptop) {
        padding-right: 0;
    }
}


.map-responsive{
    overflow:hidden;
    padding-bottom:100%;
    position:relative;
    height:0;
    @include media-query($on-laptop) {
        padding-bottom: 50%;
    }
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.contact-phone {
    font-size: 1.2em;
    font-weight: 700;
}

.contact-doxy {
    div.doxyme-badge.doxyme-badge-horizontal,
    div.doxyme-badge.doxyme-badge-noimage,
    div.doxyme-badge.doxyme-badge-noimage#doxyme-badge {
        margin: $large-spacing 0 $large-spacing;
    }
    div.doxyme-badge.doxyme-badge-horizontal a.doxyme-badge-start-button:hover,
    div.doxyme-badge.doxyme-badge-horizontal#doxyme-badge a.doxyme-badge-start-button:hover {
        background: $text-color;
    }
    div.doxyme-badge.doxyme-badge-horizontal a.doxyme-badge-start-button,
    div.doxyme-badge.doxyme-badge-horizontal#doxyme-badge a.doxyme-badge-start-button {
        background: $brand-color;
    }

    div.doxyme-badge div.doxyme-badge-start-a-call,
    div.doxyme-badge a.doxyme-badge-start-a-call,
    div.doxyme-badge#doxyme-badge div.doxyme-badge-start-a-call,
    div.doxyme-badge#doxyme-badge a.doxyme-badge-start-a-call {
        color: $brand-color !important;
    }

    div.doxyme-badge.doxyme-badge-horizontal div.doxyme-badge-content a.doxyme-badge-start-a-call,
    div.doxyme-badge.doxyme-badge-horizontal#doxyme-badge div.doxyme-badge-content a.doxyme-badge-start-a-call {
        border-bottom: 1px solid transparent;
    }

    div.doxyme-badge.doxyme-badge-horizontal div.doxyme-badge-content a.doxyme-badge-start-a-call:hover,
    div.doxyme-badge.doxyme-badge-horizontal#doxyme-badge div.doxyme-badge-content a.doxyme-badge-start-a-call:hover,
    div.doxyme-badge.doxyme-badge-noimage a.doxyme-badge-start-a-call:hover,
    div.doxyme-badge.doxyme-badge-noimage#doxyme-badge a.doxyme-badge-start-a-call:hover {
        color: $text-color !important;
        border-bottom: 1px solid $text-color;
    }
    div.doxyme-badge.doxyme-badge-noimage a.doxyme-badge-start-a-call,
    div.doxyme-badge.doxyme-badge-noimage#doxyme-badge a.doxyme-badge-start-a-call {
        color: $brand-color !important;
        border-bottom: 1px solid transparent;
    }

    .doxyme-badge-horizontal {
        display: block;
    }
    .doxyme-badge-noimage {
        display: none;
    }
    @include media-query(510px) {
        .doxyme-badge-horizontal {
            display: none;
        }
        .doxyme-badge-noimage {
            display: block;
        }
    }
}

.event-notice {
    border-top: solid 1px $divider-color;
    padding-top: $large-spacing;
    margin-bottom: $large-spacing;
}

.post-content .event-date {
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: 400;
    font-style: italic;
}

.post-list {
    > li {
        border-top: solid 1px $divider-color;
        padding-top: $spacing-unit;
    }
}

.error-message {
    color: $color-red;
}
.info-message {
    font-weight: 700;
    font-size: 1.1em;
}
#none-available-notice {
    margin: $default-spacing;
}

// Thanks to bootstrap for these styles
.custom-select {
    display: inline-block;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: auto;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1em;
}
.filters {
    padding: 0 0 ($default-spacing - 5px);
    label {
        margin-right: $default-spacing;
        margin-bottom: 5px;
        display: inline-block;
        .custom-select {
            margin-left: $small-spacing;
        }
    }
}
.scroll-table {
    display: block;
    width: 100%;
    overflow: auto;
}
.available-times-table {
    max-height: 610px; // Set the max height so that there will be some overlap of the text with the bottom to indicate that there are more items.
    margin-bottom: $large-spacing;
    table {
        margin-bottom: 0;
    }
}
.column-book {
    text-align: center;
}
.sms-booking-link {
    img {
        width: 20px;
        height: 20px;
        opacity: 0.6;
    }
    &:hover,
    &:focus,
    &:active {
        img {
            opacity: 0.8;
        }
    }
}

.alert {
    border: solid 1px $color-peach;
    padding: $default-spacing;
    margin: $default-spacing 0;
    border-radius: 3px;
    background-color: rgba($color-peach, 0.3);
    font-weight: 700;
}

/* Page specific styles*/
.post.faq {
    h2 {
        font-size: 1.4em;
        padding-top: $large-spacing;
    }
}
.post.about {
    h2 {
        padding-top: $large-spacing;
    }
}

a.download-thumbnail {
    display: inline-flex;
    flex-direction: column;
    img {
        border: solid 1px $brand-color;
        max-width: 150px;
        margin-top: $large-spacing;
    }
    :hover {
        box-shadow: 0 0 2px 0 $brand-color;
    }
    span {
        display: block;
        margin-top: $default-spacing;
        text-align: center;
    }
}

.credentials {
    font-size: 0.6em;
}

.link-icon {
    max-height: 1em;
    max-width: 1.7266em;
    margin-bottom: 0.3em;
}

/**
 * Art contest content
 */
.art-contest-floating {
    background-color: #E5A37B;
    position: absolute;
    width: 200px;
    top: Max(50vh, 30rem);
    right: 30px;
    border-radius: 5px;
    padding: 15px;
    transform: translateY(calc(-100% - 30px));
    text-align: center;
    .contest-content {
        display: flex;
        align-items: flex-start;
        h2 {
            color: 
            #660f0f;
            font-size: 1.3em;
            font-family: 'Atma', cursive;
        }
        img {
            width: 50px;
        }
    }
    .link-button {
        display: block;
    }
    @include media-query($width-hamburger) {
        transform: translateY(calc(-100% + 45px));
    }

    @include media-query(490px) {
        width: calc(100vw - 45px);
        transform: translateY(calc(-100% + 80px));
        right: 15px;
        box-sizing: border-box;
        padding-top: 10px;
        .contest-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
             h2 {
                font-size: 1.18em;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 10px;
                text-align: center;
            }
            img {
                width: 35px;
            }
        }
    }
}
.home-page .masthead {
    @include media-query($width-hamburger) {
        margin-bottom: 60px;
    }
    @include media-query(490px) {
        margin-bottom: 100px;
    }
}
/**
 * End Art contest content
 * Once the art contest is done, remove the font import on the home page and add it on the contest page in the content
 */